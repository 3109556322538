import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

const Home = {
  init: () => {
    Home.blank();
    if (window.scrollY < 500) {
      Home.time(200);
    } else {
      Home.time(0);
    }
  },
  blank: () => {
    var a = document.getElementsByTagName('a');
    [...a].forEach(link => {
      if (link.getAttribute('target')) {
        return;
      } else if (link.host !== window.location.host) {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener');
      } else {
        link.setAttribute('target', '_self');
      }
    });
  },

  time: t => {
    Home.sections = document.querySelectorAll('.js-time');
    Home.sections.forEach(item => {
      var i = item.dataset.order;
      var h = 300 + i * t;
      var time = setTimeout(function() {
        item.classList.remove('section--opacity');
      }, h);
      window.addEventListener('scroll', function() {
        clearTimeout(time);
        item.classList.remove('section--opacity');
      });
    });
  },
};

export default Home;
