import 'dom-slider';

const Toggle = {
  init: () => {
    Toggle.buttons = document.querySelectorAll('.js-titleToggle');
    Toggle.buttons.forEach(button => {
      button.addEventListener('click', () => {
        Toggle.toggle(button);
      });
    });

    Toggle.more = document.querySelectorAll('.js-more');
    Toggle.more.forEach(more => {
      more.addEventListener('click', e => {
        more.previousElementSibling.slideToggle(
          600,
          'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
        );
        more.classList.toggle('js-previousHidden');
      });
    });
  },

  toggle: el => {
    el.nextElementSibling.slideToggle(
      300,
      'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
    );
    el.querySelector('.js-arrowToggle').classList.toggle(
      'toggle__arrow--mirror',
    );
  },
};

export default Toggle;
