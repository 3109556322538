const Intro = {
  init: () => {
    Intro.sections = document.querySelectorAll('.js-intro');
    setTimeout(function() {
      Intro.sections.forEach(section => {
        section.classList.remove('js-intro');
      });
    }, 1000);
  },
};

export default Intro;
