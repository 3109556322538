import SmoothScroll from "smooth-scroll";

const Hash = {
  init: () => {
    if (document.querySelector(".content") && !document.querySelector(".content").classList.contains("t-saison") && window.location.hash) {
      var hash = window.location.href.split("#")[1];
      history.replaceState(null, null, window.location.href.split("#")[0]);
      var t =
        document.querySelector(".header").getBoundingClientRect().height + document.querySelector(".subHeader").getBoundingClientRect().height + 20;
      var scroll = new SmoothScroll({
        speed: 600,
        easing: "easeInOutQuad",
        durationMin: 400,
      });

      setTimeout(function () {
        scroll.animateScroll(document.getElementById(hash).offsetTop - t);
      }, 1000);
    }
  },
};

export default Hash;
