const Section = {
  init: () => {
    Section.buttonList = document.querySelector('.js-buttonList');
    Section.buttons = document.querySelectorAll('.js-buttonSection');
    Section.sections = document.querySelectorAll('.js-sectionToggle');
    Section.buttons.forEach(button => {
      button.addEventListener('click', function(e) {
        Section.sections.forEach(sect => {
          sect.classList.add('calendar__area--hidden');
        });
        document
          .getElementById(button.dataset.button)
          .classList.remove('calendar__area--hidden');
        Section.buttonList
          .querySelector('.button--active')
          .classList.remove('button--active');
        button.classList.add('button--active');
      });
    });
  }
};

export default Section;
