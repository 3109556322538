import SmoothScroll from "smooth-scroll";

const Website = {
  init: () => {
    var isTouch = "ontouchstart" in window || navigator.msMaxTouchPoints > 0;
    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iP(ad|hone)/i);
    var webkit = !!ua.match(/WebKit/i);
    var isiOS = iOS && webkit && !ua.match(/CriOS/i);

    var touch = isTouch ? "touch" : "no-touch";
    var os = isiOS ? "ios" : "no-ios";
    document.documentElement.classList.add(touch);
    document.documentElement.classList.add(os);

    if (document.querySelector(".header")) {
      var scroll = new SmoothScroll('a[href*="#"]', {
        speed: 300,
        easing: "easeInOutQuad",
        offset:
          document.querySelector(".header").getBoundingClientRect().height + document.querySelector(".subHeader").getBoundingClientRect().height + 20,
        durationMin: 400,
        durationMax: 800,
        updateURL: false,
        popstate: false,
      });
    }

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  },
};

export default Website;
