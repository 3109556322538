const Search = {
  init: () => {
    Search.form = document.querySelectorAll('.js-search');
    Search.form.forEach(form => {
      form.addEventListener('submit', function(e) {
        e.preventDefault();
        var text = form.children[0].value;
        location = form.dataset.url + '?q=' + text;
      });
    });
  }
};

export default Search;
