import Flickity from "flickity";

const Lightbox = {
  init: () => {
    Lightbox.container = document.querySelector(".js-lightbox");
    Lightbox.inner = document.querySelector(".js-lightboxInner");
    Lightbox.items = document.querySelectorAll(".js-mediathequeItem");
    Lightbox.close = document.querySelector(".js-lightboxClose");

    if (Lightbox.container) Lightbox.nav = Lightbox.container.querySelectorAll(".js-slideshowNav");

    Lightbox.slideOptions = {
      cellSelector: ".js-slide",
      cellAlign: "center",
      setGallerySize: false,
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      percentPosition: false,
      draggable: true,
      selectedAttraction: 0.015,
      friction: 0.25,
      lazyLoad: true,
    };

    if (Lightbox.items) {
      Lightbox.items.forEach((item) => {
        item.addEventListener("click", function (e) {
          document.body.style.overflow = "hidden";
          if (item.classList.contains("mediatheque--image")) {
            var url = item.dataset.url;
            var div = document.createElement("img");
            div.src = url;
            Lightbox.container.classList.add("active");
            Lightbox.inner.appendChild(div);
          } else if (item.classList.contains("mediatheque--video")) {
            var url = item.dataset.url;
            var div = document.createElement("iframe");
            div.src = url;
            Lightbox.container.classList.add("active");
            Lightbox.inner.appendChild(div);
          } else if (item.classList.contains("mediatheque--slideshow")) {
            if (e.target.closest(".js-mediathequeNav")) {
              Lightbox.changeSlideshow(item, e.target.closest(".js-mediathequeNav"));
            } else {
              Lightbox.createSlideshow(item);
            }
          }
        });
      });
    }

    if (Lightbox.container) {
      Lightbox.nav.forEach((nav) => {
        nav.addEventListener("click", function () {
          nav.classList.contains("next") ? Lightbox.slider.next("true") : Lightbox.slider.previous("true");
        });
      });
    }

    if (Lightbox.close) {
      Lightbox.close.addEventListener("click", function () {
        if (Lightbox.slider) Lightbox.slider.destroy();
        Lightbox.container.classList.remove("active");
        document.body.style.overflow = "";
        Lightbox.inner.innerHTML = "";
      });
    }
  },

  createSlideshow: (item) => {
    Lightbox.inner.style.opacity = 0;
    var medias = item.querySelector(".js-mediathequeSlideshow").children;
    Array.from(medias).forEach((media) => {
      var url = media.src;
      var div = document.createElement("div");
      var img = document.createElement("img");
      div.classList = "js-slide lightbox__slide";
      img.src = url;
      Lightbox.inner.appendChild(div);
      div.appendChild(img);
    });
    Lightbox.container.classList.add("active");
    setTimeout(function () {
      Lightbox.slider = new Flickity(Lightbox.inner, Lightbox.slideOptions);
      Lightbox.inner.style.opacity = 1;
    }, 100);
  },

  changeSlideshow: (item, nav) => {
    let newSlide;
    const slides = item.querySelectorAll(".js-mediathequeSlideshow img");
    const activeSlide = item.querySelector(".js-mediathequeSlideshow img.active");
    const activeSlideIndex = Array.from(slides).indexOf(activeSlide);
    if (nav.classList.contains("next")) {
      newSlide = activeSlideIndex > slides.length - 2 ? slides[0] : activeSlide.nextElementSibling;
    } else {
      newSlide = activeSlideIndex < 1 ? slides[slides.length - 1] : activeSlide.previousElementSibling;
    }
    newSlide.classList.add("active");
    activeSlide.classList.remove("active");
  },
};

export default Lightbox;
