import L from "leaflet";

const Maps = {
  init: () => {
    Maps.element = document.querySelectorAll(".js-map");
    Maps.bigMap = document.querySelector(".js-bigMap");

    Maps.access = {
      maxZoom: 18,
      id: "quentin-f451/ckfzghns917lo19mlca0yqxo7",
      tileSize: 512,
      zoomOffset: -1,
      accessToken:
        "pk.eyJ1IjoicXVlbnRpbi1mNDUxIiwiYSI6ImNqdzB3Y3dybTBlZ2c0Nmt0dnpqenF5N3IifQ.n9DF0q7NxE_Z3CXcuhK-xg",
    };

    Maps.decagone = L.divIcon({
      html:
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1635.4"><path d="M308.5,641.9V419.6l119.4-116.1h222.3l119.4,116.1v222.3L650.2,758H427.9L308.5,641.9z M0,787.8l280.3,272l194.1,575.5H607 l195.7-580.5l275.3-267V273.7L796.1,0H282L0,273.7V787.8z"/></svg>',
      iconSize: [24, 24],
      iconAnchor: [12, 24],
      popupAnchor: [0, -24],
    });

    Maps.popup = {
      closeButton: false,
      className: "lieu__popup",
    };

    Maps.element.forEach((map) => {
      var long = map.dataset.long;
      var lat = map.dataset.lat;
      var mymap = L.map(map).setView([lat, long], 13);
      L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
        Maps.access
      ).addTo(mymap);

      var marker = L.marker([lat, long], {
        icon: Maps.decagone,
      }).addTo(mymap);
      var popup = L.popup(Maps.popup).setContent(map.dataset.title);
      marker.bindPopup(popup).openPopup();
      mymap.scrollWheelZoom.disable();
    });

    if (Maps.bigMap) Maps.initBig();
  },

  initBig: () => {
    var bigmap = L.map(Maps.bigMap).setView([49.02, 2.25], 11);
    Maps.markers = [];
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
      Maps.access
    ).addTo(bigmap);

    Array.from(Maps.bigMap.nextElementSibling.children).forEach((lieu) => {
      var marker = L.marker([lieu.dataset.lat, lieu.dataset.long], {
        icon: Maps.decagone,
      }).addTo(bigmap);
      Maps.markers.push(marker);
      var popup = L.popup(Maps.popup).setContent(lieu.dataset.title);
      marker.bindPopup(popup);
      bigmap.scrollWheelZoom.disable();
    });

    var group = new L.featureGroup(Maps.markers);
    bigmap.fitBounds(group.getBounds());
  },
};

export default Maps;
