const Popup = {
  init: () => {
    Popup.container = document.querySelector(".js-popup");
    Popup.close = document.querySelector(".js-popupClose");

    if (Popup.container) {
      Popup.container.addEventListener("click", function (e) {
        if (e.target.classList.contains("js-popup")) {
          Popup.container.classList.add("inactive");
          setTimeout(() => {
            Popup.container.outerHTML = "";
          }, 350);
        }
      });
    }

    if (Popup.close) {
      Popup.close.addEventListener("click", function (e) {
        Popup.container.classList.add("inactive");
        setTimeout(() => {
          Popup.container.outerHTML = "";
        }, 350);
      });
    }
  },
};

export default Popup;
