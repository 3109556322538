import Isotope from "isotope-layout";
import imagesLoaded from "imagesloaded";
import Menu from "./menu";
import SmoothScroll from "smooth-scroll";

const Grid = {
  init: () => {
    Grid.elements = document.querySelectorAll(".js-isotope");
    Grid.buttonList = document.querySelector(".js-buttonList");
    Grid.buttons = document.querySelectorAll(".js-buttonGrid");
    Grid.layoutButtons = document.querySelectorAll(".js-changeLayout");
    Grid.filters = document.querySelectorAll(".js-filters");
    Grid.filtersMenu = document.querySelectorAll(".js-filtersMenu");
    Grid.filterLists = document.querySelectorAll(".js-filterList");
    Grid.resetFilters = document.querySelector(".js-resetFilters");
    Grid.openFilter = document.querySelector(".js-openFilters");
    Grid.toggleButtons = document.querySelectorAll(".js-gridToggle");
    Grid.allSeason = document.querySelector(".js-allSeason");

    if (Grid.openFilter) {
      Grid.openFilter.addEventListener("click", function () {
        Grid.toggleFilters(true);
      });
    }

    Grid.scroll = new SmoothScroll();

    Grid.isotopes = [];

    Grid.groups = ["type", "humeur", "jour", "mois", "heure", "lieu", "autre"];
    Grid.arrayOfFilters = [];

    for (let i = 0; i < Grid.groups.length; i++) {
      Grid.arrayOfFilters[Grid.groups[i]] = [];
    }

    Grid.filterValue = "";
    Grid.isotopeOptions = {
      itemSelector: ".js-gridItem",
      layoutMode: "masonry",
      masonry: {
        gutter: 40,
      },
    };

    Grid.hashFilter = Grid.getHashFilter();
    if (Grid.hashFilter) {
      Grid.hash();
    }

    if (document.getElementById("prochainement") != null) {
      Grid.scrollTop();

      var timeout = window.scrollY > 0 ? 800 : 0;

      setTimeout(function () {
        Grid.elements.forEach((el) => {
          Grid.isotopeOptions.filter = Grid.filterValue;
          const iso = Grid.isotopeGrid(el, Grid.isotopeOptions);
        });
      }, timeout);
    } else {
      Grid.elements.forEach((el) => {
        Grid.isotopeOptions.filter = Grid.filterValue;
        const iso = Grid.isotopeGrid(el, Grid.isotopeOptions);
      });
    }

    Grid.buttons.forEach((button) => {
      button.addEventListener("click", function (e) {
        Grid.filter(this);
      });
    });

    Grid.filters.forEach((filter) => {
      filter.addEventListener("click", function (e) {
        Grid.filterMultiple(this);
        Grid.toggleFilters(false);
      });
    });

    Grid.filtersMenu.forEach((filter) => {
      filter.addEventListener("click", function (e) {
        Grid.reset();
        Grid.filterMenu(this);
        Grid.toggleFilters(false);
        Menu.toggle(document.querySelector(".js-openMenu"));
      });
    });

    Grid.layoutButtons.forEach((button) => {
      button.addEventListener("click", function (e) {
        Grid.changeLayout(this);
      });
    });

    if (Grid.resetFilters) {
      Grid.resetFilters.addEventListener("click", function (e) {
        Grid.scrollTop();
        Grid.reset();
        Grid.toggleFilters(false);
      });
    }
  },

  scrollTop: () => {
    var options = {
      speed: 600,
      easing: "easeInOutQuad",
      speedAsDuration: true,
    };

    Grid.scroll.animateScroll(
      document.getElementById("prochainement").offsetTop -
        document.querySelector(".header").getBoundingClientRect().height -
        document.querySelector(".subHeader").getBoundingClientRect().height -
        20,
      null,
      options
    );
  },

  toggleFilters: (toggle) => {
    if (toggle) {
      document.querySelector(".js-filterArea").classList.toggle("filters--in");
    } else {
      document.querySelector(".js-filterArea").classList.remove("filters--in");
    }
    Grid.openFilter.innerHTML = document.querySelector(".js-filterArea").classList.contains("filters--in") ? "Fermer" : "Filtrer";
  },

  isotopeGrid: (element, options) => {
    imagesLoaded(element, function () {
      Grid.isotope = new Isotope(element, options);
      if (
        Grid.isotope.element.parentNode.previousElementSibling != null &&
        Grid.isotope.element.parentNode.previousElementSibling.classList.contains("js-gridToggle")
      ) {
        if (Grid.isotope.filteredItems.length < 1) {
          Grid.isotope.element.parentNode.previousElementSibling.classList.add("DOM-slider-hidden");
        } else {
          Grid.isotope.element.parentNode.previousElementSibling.classList.remove("DOM-slider-hidden");
        }
      }
      Grid.isotopes.push(Grid.isotope);
    });
  },

  hash: () => {
    Grid.hashvalues = [];
    Grid.filterValue = Grid.hashFilter.split(",");
    Grid.filterValue.forEach((filter) => {
      var f = filter.split(".");
      f.forEach((a) => {
        Grid.hashvalues.push(a);
      });
    });

    Grid.hashvalues = Grid.hashvalues.filter(Boolean);
    Grid.hashvalues = Grid.hashvalues.filter(function (str) {
      return /\S/.test(str);
    });
    Grid.hashvalues = Grid.hashvalues.filter(function (item, pos) {
      return Grid.hashvalues.indexOf(item) == pos;
    });
    Grid.hashvalues = Grid.hashvalues.map((x) => {
      return "." + x;
    });

    Grid.filters.forEach((filter) => {
      if (Grid.hashvalues.indexOf(filter.dataset.filter) > -1) {
        filter.classList.add("filters__element--active");
        Grid.multiple(filter, true);
      }
    });

    Grid.filtersMenu.forEach((filter) => {
      if (Grid.hashvalues == filter.dataset.filter) {
        Array.from(filter.parentNode.children).forEach((f) => {
          f.classList.remove("menu__sub--active");
        });
        filter.classList.add("menu__sub--active");
      }
    });
  },

  filter: (button) => {
    var filterValue = button.getAttribute("data-filter");
    Grid.filterValue = filterValue;
    Grid.isotopes.forEach((el) => {
      Grid.isotopeArrange(el);
    });
    Grid.buttonList.querySelector(".button--active").classList.remove("button--active");
    button.classList.add("button--active");
  },

  filterMenu: (filter) => {
    Array.from(filter.parentNode.children).forEach((f) => {
      f.classList.remove("menu__sub--active");
    });
    if (!filter.classList.contains("menu__sub--active")) Grid.multiple(filter, true);
    filter.classList.add("menu__sub--active");

    Grid.filters.forEach((f) => {
      if (f.dataset.filter == filter.dataset.filter) f.classList.add("filters__element--active");
    });

    Grid.filterValue = Grid.getComboFilter(Grid.arrayOfFilters);

    Grid.toggleButtons.forEach((button) => {
      button.nextElementSibling.slideDown(300, "cubic-bezier(0.230, 1.000, 0.320, 1.000)");
      button.querySelector(".js-arrowToggle").classList.add("toggle__arrow--mirror");
    });

    location.hash = "filter=" + encodeURIComponent(Grid.filterValue);

    Grid.scrollTop();

    var timeout = window.scrollY > 0 ? 800 : 0;

    setTimeout(function () {
      Grid.isotopes.forEach((el) => {
        Grid.isotopeArrange(el);
      });
    }, timeout);
  },

  filterMultiple: (filter) => {
    if (filter.classList.contains("filters__element--active")) {
      filter.classList.remove("filters__element--active");
      Grid.multiple(filter, false);
    } else {
      filter.classList.add("filters__element--active");
      Grid.multiple(filter, true);
    }

    Grid.filterValue = Grid.getComboFilter(Grid.arrayOfFilters);

    var t = false;
    Grid.filtersMenu.forEach((filter) => {
      if (Grid.filterValue == filter.dataset.filter) t = true;
    });

    Grid.filtersMenu.forEach((filter) => {
      filter.classList.remove("menu__sub--active");
      if (t) {
        if (Grid.filterValue == filter.dataset.filter) {
          Grid.allSeason.classList.remove("menu__sub--active");
          filter.classList.add("menu__sub--active");
        }
      } else {
        Grid.allSeason.classList.add("menu__sub--active");
      }
    });

    Grid.toggleButtons.forEach((button) => {
      button.nextElementSibling.slideDown(300, "cubic-bezier(0.230, 1.000, 0.320, 1.000)");
      button.querySelector(".js-arrowToggle").classList.add("toggle__arrow--mirror");
    });

    location.hash = "filter=" + encodeURIComponent(Grid.filterValue);

    Grid.scrollTop();

    var timeout = window.scrollY > 0 ? 800 : 0;

    setTimeout(function () {
      Grid.isotopes.forEach((el) => {
        Grid.isotopeArrange(el);
      });
    }, timeout);
  },

  isotopeArrange: (el) => {
    el.arrange({
      filter: Grid.filterValue,
    });
    if (el.element.parentNode.previousElementSibling != null && el.element.parentNode.previousElementSibling.classList.contains("js-gridToggle")) {
      if (el.filteredItems.length < 1) {
        el.element.parentNode.previousElementSibling.classList.add("DOM-slider-hidden");
      } else {
        el.element.parentNode.previousElementSibling.classList.remove("DOM-slider-hidden");
      }
    }
  },

  changeLayout: (button) => {
    Grid.isotopes = [];
    Grid.elements.forEach((el) => {
      Grid.isotope.destroy();
    });
    Grid.layoutButtons.forEach((button) => {
      button.classList.remove("subHeader__icon--active");
    });
    button.classList.add("subHeader__icon--active");
    Grid.isotopeOptions.layoutMode = button.classList.contains("subHeader__icon--line") ? "vertical" : "masonry";
    Grid.isotopeOptions.filter = Grid.filterValue;

    Grid.elements.forEach((el) => {
      if (button.classList.contains("subHeader__icon--line")) {
        el.classList.add("grid--line");
      } else {
        el.classList.remove("grid--line");
      }
      const iso = Grid.isotopeGrid(el, Grid.isotopeOptions);
    });
  },

  reset: () => {
    Grid.filterValue = "*";

    Grid.filters.forEach((filter) => {
      filter.classList.remove("filters__element--active");
    });
    history.pushState(null, null, window.location.href.split("#")[0]);

    Grid.arrayOfFilters = [];

    for (let i = 0; i < Grid.groups.length; i++) {
      Grid.arrayOfFilters[Grid.groups[i]] = [];
    }

    Grid.filtersMenu.forEach((filter) => {
      filter.classList.remove("menu__sub--active");
      Grid.allSeason.classList.add("menu__sub--active");
    });

    Grid.scrollTop();

    var timeout = window.scrollY > 0 ? 800 : 0;

    setTimeout(function () {
      Grid.isotopes.forEach((el) => {
        Grid.isotopeArrange(el);
      });
    }, timeout);
  },

  multiple: (button, add) => {
    var buttonGroup = button.parentNode;
    var filterGroup = buttonGroup.dataset.filterGroup;
    var fValue = button.dataset.filter;
    if (add) {
      Grid.arrayOfFilters[filterGroup].push(fValue);
    } else {
      Grid.arrayOfFilters[filterGroup] = Grid.arrayOfFilters[filterGroup].filter((e) => e !== fValue);
    }
  },

  getComboFilter: (filters) => {
    var i = 0;
    var comboFilters = [];
    var message = [];
    for (var prop in filters) {
      message.push(filters[prop].join(" "));
      var filterGroup = filters[prop];

      if (!filterGroup.length) {
        continue;
      }
      if (i === 0) {
        comboFilters = filterGroup.slice(0);
      } else {
        var filterSelectors = [];
        var groupCombo = comboFilters.slice(0); // [ A, B ]
        for (var k = 0, len3 = filterGroup.length; k < len3; k++) {
          for (var j = 0, len2 = groupCombo.length; j < len2; j++) {
            filterSelectors.push(groupCombo[j] + filterGroup[k]); // [ 1, 2 ]
          }
        }
        comboFilters = filterSelectors;
      }
      i++;
    }
    comboFilters.sort();
    var comboFilter = comboFilters.join(", ");
    return comboFilter;
  },

  getHashFilter: () => {
    var hash = location.hash;
    var matches = location.hash.match(/filter=([^&]+)/i);
    if (!matches) {
      return "";
    }
    return decodeURIComponent(matches[1]);
  },
};

export default Grid;
