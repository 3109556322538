import "./_lib/modernizr";
import "../scss/application.scss";

import Website from "./_modules/website";
import Home from "./_modules/home";
import Menu from "./_modules/menu";
import Slideshow from "./_modules/slideshow";
import Toggle from "./_modules/toggle";
import Grid from "./_modules/grid";
import Section from "./_modules/section";
import Scroll from "./_modules/scroll";
import Media from "./_modules/media";
import Maps from "./_modules/maps";
import Hash from "./_modules/hash";
import Logotype from "./_modules/logotype";
import Search from "./_modules/search";
import Intro from "./_modules/intro";
import Lightbox from "./_modules/lightbox";
import Popup from "./_modules/popup";

document.addEventListener("DOMContentLoaded", function () {
  if (document.documentElement.classList.contains("no-cssgrid")) {
    document.querySelector(".outdated").classList.remove("outdated--hidden");
  }

  Website.init();
  Hash.init();
  Logotype.init();
  Search.init();
  Home.init();
  Menu.init();
  Slideshow.init();
  Toggle.init();
  Grid.init();
  Section.init();
  Scroll.init();
  Media.init();
  Maps.init();
  Intro.init();
  Lightbox.init();
  Popup.init();
});
