import Flickity from "flickity";

const Slideshow = {
  init: () => {
    Slideshow.slideshows = document.querySelectorAll(".js-slideshow");
    Slideshow.slides = document.querySelectorAll(".js-slide");
    Slideshow.nav = document.querySelectorAll(".js-slideshowNav");
    Slideshow.slideOptions = {
      cellSelector: ".js-slide",
      cellAlign: "center",
      setGallerySize: false,
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      percentPosition: false,
      draggable: false,
      selectedAttraction: 0.015,
      friction: 0.25,
      lazyLoad: true,
    };
    Slideshow.slideshows.forEach((slideshow) => {
      if (slideshow.classList.contains("js-slideshowFeatured")) Slideshow.slideOptions.pageDots = true;
      // Slideshow.resize(slideshow);
      // window.addEventListener('resize', function() {
      //   Slideshow.resize(slideshow);
      // });
      if (slideshow.classList.contains("js-slideshowDraggable")) Slideshow.slideOptions.draggable = true;
      const carousel = Slideshow.flickity(slideshow, Slideshow.slideOptions, 6000);
    });
  },

  resize: (slideshow) => {
    if (slideshow.classList.contains("js-slideshowFeatured")) {
      var t = [];
      slideshow.querySelectorAll(".js-gridFeatured").forEach((i) => {
        t.push(i.getBoundingClientRect().height + 102);
      });
      slideshow.style.height = Math.max(...t) + "px";
    }
  },

  flickity: (element, options, timing) => {
    Slideshow.slider = new Flickity(element, options);

    if (element.classList.contains("js-slideshowFeatured")) {
      if (Slideshow.slides[0].querySelector(".featured__image")) {
        var currentColor = Slideshow.slides[0].querySelector(".featured__image").dataset.color;
      } else {
        var currentColor = "black";
      }
      Slideshow.slider.pageDots.selectedDot.style.color = currentColor;
    }

    Slideshow.interval(timing);

    Slideshow.slider.on("change", function (index) {
      Slideshow.interval(timing);
      if (element.classList.contains("js-slideshowFeatured")) {
        if (Slideshow.slides[index].querySelector(".featured__image")) {
          var currentColor = Slideshow.slides[index].querySelector(".featured__image").dataset.color;
        } else {
          var currentColor = "black";
        }
        Slideshow.slider.pageDots.dots.forEach((dot) => {
          dot.style.color = "black";
        });
        Slideshow.slider.pageDots.selectedDot.style.color = currentColor;
      }
    });

    Slideshow.nav.forEach((nav) => {
      nav.addEventListener("click", function () {
        window.clearTimeout(Slideshow.intervalSlide);
        nav.classList.contains("next") ? Slideshow.slider.next("true") : Slideshow.slider.previous("true");
      });
    });

    Slideshow.slides.forEach((slide) => {
      slide.addEventListener("mouseover", function () {
        Slideshow.interval(timing);
      });
    });

    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState == "hidden") {
        window.clearTimeout(Slideshow.intervalSlide);
      } else {
        Slideshow.interval(timing);
      }
    });

    return Slideshow.slider;
  },
  interval: (timing) => {
    window.clearTimeout(Slideshow.intervalSlide);
    Slideshow.intervalSlide = window.setTimeout(function () {
      var selected = Slideshow.slider.selectedElement;
      if (selected.getElementsByTagName("iframe").length == 0) {
        Slideshow.slider.next("true");
      }
    }, timing);
  },
};

export default Slideshow;
