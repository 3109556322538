import scrollToWithAnimation from "scrollto-with-animation";
import inViewport from "in-viewport";

const Scroll = {
  init: () => {
    Scroll.buttons = document.querySelectorAll(".js-scrollButton");
    Scroll.prevInView = "";
    Scroll.scrollSectionSub = document.querySelector(".subHeader__section--scroll");

    Scroll.sections = document.querySelectorAll(".js-inview");
    Scroll.getSectionsInView();
    window.addEventListener("scroll", function () {
      Scroll.getSectionsInView();
    });

    if (Scroll.scrollSectionSub) {
      Scroll.scrollSectionSub.addEventListener("scroll", function () {
        if (Scroll.scrollSectionSub.scrollLeft >= 20) {
          Scroll.scrollSectionSub.previousElementSibling.classList.add("subHeader__section--shadow");
        } else {
          Scroll.scrollSectionSub.previousElementSibling.classList.remove("subHeader__section--shadow");
        }
      });
    }
  },

  getSectionsInView: () => {
    Scroll.sectionsInView = [];
    Scroll.sections.forEach((section) => {
      if (inViewport(section)) Scroll.sectionsInView.push(section);
    });
    if (Scroll.sectionsInView.length == 1) {
      var inView = Scroll.sectionsInView[0];
    } else if (Scroll.sectionsInView.length < 1) {
      var inView = null;
    } else {
      var sectionsPos = [];
      var goal = window.innerHeight / 2;
      Scroll.sectionsInView.forEach((section) => {
        var a = Math.abs(section.getBoundingClientRect().bottom - goal);
        var b = Math.abs(section.getBoundingClientRect().top - goal);
        var c = a >= b ? section.getBoundingClientRect().top : section.getBoundingClientRect().bottom;
        sectionsPos.push(c);
      });
      var closest = sectionsPos.reduce((prev, curr) => (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev));
      var inView = Scroll.sectionsInView[sectionsPos.indexOf(closest)];
    }
    Scroll.buttons.forEach((button) => {
      button.classList.remove("active");
    });
    if (inView != null) {
      var inButton = document.querySelector('.js-scrollButton a[href="#' + inView.id + '"]');
      if (!inButton) return false;
      inButton.parentNode.classList.add("active");

      if (inView != Scroll.prevInView) {
        setTimeout(function () {
          scrollToWithAnimation(
            Scroll.scrollSectionSub,
            "scrollLeft",
            inButton.offsetLeft - Scroll.scrollSectionSub.getBoundingClientRect().left - 20,
            200,
            "linearTween"
          );
          Scroll.prevInView = inView;
        }, 200);
      }
    }
  },
};

export default Scroll;
