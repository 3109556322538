const Menu = {
  init: () => {
    Menu.button = document.querySelector(".js-openMenu");
    if (!Menu.button) return;
    Menu.button.addEventListener("click", function () {
      Menu.toggle(this);
    });
  },

  toggle: (button) => {
    document.querySelector(".js-menu").classList.toggle("menu--in");
    button.parentNode.classList.toggle("active");
  },
};

export default Menu;
