import Plyr from 'plyr';

const Media = {
  init: () => {
    Media.players = document.querySelectorAll('.js-player');

    Media.options = {
      controls: [
        'play',
        'progress',
        'current-time',
        'duration',
        'mute',
        'fullscreen',
      ],
      iconUrl: '/assets/svg/sprite.svg',
      iconPrefix: 'player',
      autoplay: false,
      clickToPlay: true,
      fullscreen: { enabled: true, fallback: true, iosNative: true },
    };

    Media.players.forEach(player => {
      if (
        player.classList.contains('js-playerInSlide') ||
        document.documentElement.classList.contains('touch')
      ) {
        Media.options.controls = ['play-large'];
      } else {
        Media.options.controls = [
          'play',
          'progress',
          'current-time',
          'duration',
          'mute',
          'fullscreen',
        ];
      }
      new Plyr(player, Media.options);
    });
  },
};

export default Media;
