const Logotype = {
  init: () => {
    Logotype.sections = document.querySelectorAll('.js-logotype');
    window.addEventListener('scroll', e => {
      Logotype.sections.forEach(section => {
        section.classList.remove('js-logotype');
      });
    });
  },
};

export default Logotype;
